import { useEffect } from 'react';
import { useTargetNetwork } from '../../web3/web3';

const NetworkSetter = ({ chainId }) => {
    const { setTargetNetwork } = useTargetNetwork();
    useEffect(() => {
        if (setTargetNetwork) {
            setTargetNetwork(chainId);
        }
    }, [setTargetNetwork, chainId]);

    return null;
};

export default NetworkSetter;