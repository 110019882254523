
import React, { useCallback, useContext } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import NetworkContext from '../../context/network-context';
import { useFundApr } from '../../state/fund-info';
import { useInvestorClaimFee, useInvestorPendingRewards, useInvestorPoolShare, useInvestorRewardStatus, useRewardActions } from '../../state/investor-info';

function RewardClaim() {
    const { poolShare } = useInvestorPoolShare();
    const { pendingRewards } = useInvestorPendingRewards();
    const { apr } = useFundApr();
    const { activateReward, claimReward } = useRewardActions();
    const { account, connected, isCorrectNetwork, pendingTransaction, executeTransaction } = useContext(NetworkContext);
    const { rewardEligible } = useInvestorRewardStatus();
    const { claimFee } = useInvestorClaimFee();

    const handleActivate = useCallback(async () => {
        const tx = async () => await activateReward(account);

        await executeTransaction({
            message: 'Activation ',
            tx
        });
    }, [account, executeTransaction, activateReward]);

    const handleClaim = useCallback(async () => {
        const tx = async () => await claimReward(account);

        await executeTransaction({
            message: 'Claiming ',
            tx
        });
    }, [account, claimReward, executeTransaction]);
    return (
        <div className="reward-claim-component h-100">
            <Card>
                <Card.Body className="d-flex flex-column justify-content-between">
                    <div className="d-flex flex-column justify-content-between align-items-center flex-grow-1">

                        <div className="w-100 row justify-content-between" style={{ marginBottom: '1.25rem' }}>
                            <h3 className="mb-0">Rewards</h3>
                            <small>Reward claiming is free 30 days after the previous claim. See rewards <a href="http://docs.hedgepay.org" target="_blank" rel="noopener noreferrer">fee info</a></small>
                        </div>
                        <div className="w-100">
                            <Row >
                                <Col className="farming-stats-group text-start">
                                    <small className="farming-stats-value-label">Reward Pool Share</small>
                                    <p className="farming-stats-value-item mb-0">
                                        <CountUp isCounting end={+poolShare} decimals={2} preserveValue={true} separator=',' /> %
                                    </p>
                                </Col>

                                <Col className="farming-stats-group text-end">
                                    <small className="farming-stats-value-label">Fund APR</small>
                                    <p className="farming-stats-value-item">
                                        <CountUp isCounting end={+apr} decimals={2} preserveValue={true} separator=',' /> %
                                    </p>
                                </Col>
                            </Row>
                            <Row >
                                <Col className="farming-stats-group text-start">
                                    <small className="farming-stats-value-label">Pending Rewards</small>
                                    <p className="farming-stats-value-item mb-0">
                                        $ <CountUp isCounting end={+pendingRewards} decimals={8} preserveValue={true} separator=',' />
                                    </p>
                                </Col>

                                <Col className="farming-stats-group text-end">
                                    <small className="farming-stats-value-label">Fee </small>
                                    <p className="farming-stats-value-item">
                                        <CountUp isCounting end={+claimFee} decimals={2} preserveValue={true} separator=',' /> %
                                    </p>
                                </Col>
                            </Row>


                        </div>
                        <div className="w-100">
                            <Row >

                                {(!connected || rewardEligible === 'notEligible') && <p className="mt-2 text-center w-100">Hold at least 800,000 HPAY to unlock BUSD rewards. </p>}
                                {connected && rewardEligible === 'notActive' && <Button className="mt-auto w-100" onClick={handleActivate} disabled={!connected || !isCorrectNetwork || !!pendingTransaction}
                                    >Activate Rewards</Button>}
                                {connected && rewardEligible === "eligible" && <Button onClick={handleClaim} disabled={!connected || !isCorrectNetwork || !!pendingTransaction || pendingRewards === 0}
                                    className="text-white">Claim Rewards</Button>}
                            </Row>

                        </div>
                    </div>
                </Card.Body>
            </Card >
        </div>
    );
}

export default RewardClaim;
